export default {
	web: {
		index: {
			login: "登录",
			sigupForFree: "免费注册"
		},
		signin: {
			email: "请输入邮箱",
			password: "请输入密码"
		},
		signup: {
			email: "请输入邮箱",
			password: "请输入密码（8-20位）",
			repassword: "确认输入密码"
		},
		rules: {
			emailCount: "长度不少于5位数",
			passwordCount: "长度在8到20位数",
		},
		forgetpassword: {
			email: "您的邮箱",
			code: "验证码"
		}
	},
	common: {
		close: "关闭",
		save: "保存",
    bye: "再见",
		cancel: "取消",
		sure: "确定",
		back: "返回",
		refresh: "刷新",
		update: "更新",
		add: "添加",
		clear: "清空",
		inDevelopment: "正在开发",
		items: "项",
		retry:"重试",
		unopened: "暂未开放",
		traffic: "流量"
	},
	home: {
		myDrives: "云盘管理",
		transfer: "云传输",
		myTasks: "任务列表"
	},
	cloud: {
		new: "云盘",
		emptuyCloudList: "云盘空空如也",
		emptuyList: "空空如也",
		newFolder: "新建文件夹",
		emptyFolder: "这个文件夹是空的.",
		refresh: "刷新云盘",
		update: "修改名称",
		delete: "移除云盘",
		deletingCloud: "移除云盘",
		deleteTip: "该云盘有任务正在进行中, 移除云盘将会停止并删除与该云盘相关的所有任务, 确定要继续移除吗？",
		addSuccessTip: "云盘授权成功",
		addExistTip: "云盘在列表已存在",
		addFailTip: "云盘授权失败，或联系客服",
		addSuccess: "云盘添加成功, 请回到界面并刷新云盘列表",
		addExplain: "您可以在以下以开放的云盘列表中点击进行授权登录，点击后将弹出的第三方授权登录页面或跳转到第三方官网授权。",
		baidu: "百度网盘",
		chinamobile: "中国移动云盘",
		drive123: "123云盘",
		tencent: "腾讯微云",
    ftp:"FTP",
    thunder:"迅雷云盘",
    wps:"WPS文档",
    quark:"夸克网盘",
		aliyun: "阿里云盘",
		onedrive: "微软云盘",
		googleDrive: "谷歌云盘",
		dropbox: "Dropbox",
		future: "在未来，我们将开发和支持更多的第三方云存储产品，如果你有想要添加的云存储类型，你可以通过电子邮件告诉我们，后续开放：",
		explorer: {
			myFiles: "根目录",
			name: "名称",
			size: "大小",
			updateTime: "更新时间",
			open: "打开",
			refresh: "刷新",
			delete: "删除",
			preview: "预览",
			rename: "重命名",
			download: "下载",
			copyTo: "复制到",
			moveTo: "移动到",
			site: "官网",

		},
		file: {
			pic: "图片",
			vedio: "视频",
			audio: "音频",
			book: "书籍"
		}
	},
	task: {
		new: "任务",
		title: "任务列表",
		newTask: "新建任务",
		deleteEmpty: "清空列表",
		empty: "任务列表是空的",
		restart: "重启",
		delete:"删除",
		completed: "文件成功率",
		status: {
			running: "运行中",
			waiting: "等待",
			success: "成功",
			failure: "失败",
			partialSuccess: "部分成功",
			cancel: "取消",
			unkown: "未知"
		},
		delTitle: "删除任务",
		delTip: "是否要删除该任务？如果任务正在进行，删除后将会停止。",
		itemsSelected: "项被选中",
		sourceNotSelect: "未选择传输源",
		destinationNotSelect: "未选择传输目的地",
    createTip:"云端传输，无需在线，随时随地可登录查看.",
	},
	transfer: {
		addNewCloud: "添加云盘",
		source: "选择来源",
		baiduRoot: "当前空间位于\"我的应用数据/小迈千云\"",
		destination: "选择目标",
		setting: "设置",
		chipLable: "后缀名如: mp4",
		filterTip: "文件类型过滤器允许你设置自己的过滤规则,包含或者排除设置的选项,系统将根据文件名称的后缀来确定,您可以输入文件类型,如zip、jpg:",
		include: "包含下列文件",
		exclude: "排除下列文件",
		conflictTile: "文件冲突策略",
		taskName: "任务名称",
		cloudTips: "来源和目标单击名称可以进入云盘或文件夹",
		sendEmail: "任务完成后将结果发送到邮件",
		createNow: "立即运行",
		inLine: "该任务正在排队",
		calculating: "正在计算",
		timeRemaining: "估算时间",
		baiduRateTip: "百度非SVIP受官方限速影响",
		folder: "文件夹",
		file: "文件",
		size: "大小",
		skipped: "已跳过",
		failed: "已失败",
		stopTitle: "停止任务",
		stopTip: "是否要停止当前正在运行的任务？",
		stop: "停止",
		cancel: "取消",
		loading: "加载中...",
		from: "来源",
		to: "目标",
		total: "共",
		items: "项",
		stoppingTask: "正在停止任务",
		taskSetting: "任务设置",
		fileConfict: "文件冲突策略",
		confictTip: "在云盘数据传输过程中,若发生文件冲突,将采取您在下列中所选中的策略来处理。",
		notificationEmail: "通知邮件",
		notificationEmailTip: "请填写您的邮箱地址",
		time: "时间",
		avgRate: "平均速度",
		startTime: "开始时间",
		endTime: "结束时间",
		failDetails: "错误详情",
		FailDetails: "错误列表",
		path: "路径",
		cause: "原因",
	},
	user: {
    vipTipTitle:"客服技术服务反馈群",
    vipNow:"立即加入",
    vipGroup:"用户交流群",
    vipDesc:"您可以扫描下方微信二维码，加入用户交流群，也可以向客户反馈遇到的问题或系统故障，我们将最快为您响应。",
		messageWant:"在这里反馈问题",
    messageBoardBut:"留言板",
    changeAvatar: "更换头像",
		avatarPreview: "预览",
		avatarUpload: "上传头像",
		avatarUploadClick: "点击上传",
		orderList: "订单记录",
		changePassword: "修改密码",
		resetPassword: "修改密码",
		newPassword: "输入你的新密码",
		newPasswordCheck: "密码长度限制在8-20位",
		oldPassword: "输入你的旧密码",
		oldPasswordCheck: "密码长度限制在8-20位",
		passwordLimit: "至少8位长度",
		passwordSuccess: "密码修改成功",
		showPassword: "显示",
		logout: "退出",
    del:"注销用户",
		connectUs: "联系我们",
		start: "开始",
		feedback: "问题反馈",
		passwordCheck: "密码长度限制在8-20位",
		unlimited: "无限",
		sessionExpired: "登录会话已过期，请重新登录",
		feedbackDescribe: "亲爱的用户，非常感谢您使用我们的产品，如果在使用过程中出现问题，您可以反馈到以下邮箱，我们将在24小时内处理并回复您。在邮件中，您可以带来您的账号、问题详情、配合操作步骤或图片、视频等，这有利于帮您更快的定位问题，谢谢!"
	},
	gift: {
		type: {
			MONTHLY: "月会员用户"
		},
		pricingTable: "流量套餐",
		orderDetails: "订单详情",
		describe: "单月多次购买月付费套餐可叠加，有了您的支持我们才能给您更好，感谢支持！",
		currentPlan: "当前套餐",
		order: "立即订购",
    vipDocument:"付费权益",
    freeDocument:"免费权益",
		a: {
			title: {
				price: "10G/月",
				sale: "￥0",
				name: "免费版",
				number:"2312Free",
				id:"1"
			},
      color:"#79b996",
			content:free()
		},
		b: {
			title: {
				price: "100G/月",
				ext:"30G",
				sale: "￥26",
				name: "￥16",
				number:"100G+30G-M2312",
				id:"3"
			},
			content: vip()
		},
		c: {
      title: {
				price: "500G/月",
				ext:"",
				sale: "￥75",
				name: "￥29.9",
				number:"500G-31D-M2412",
				id:"16"
			},
      color:"#EF6C00",
			content:vip()
		},
		d: {
			title: {
				price: "2T/月",
				ext:"",
				sale: "￥164",
				name: "￥59.9",
				number:"2000G-31D-M2401",
				id:"14"
			},
      color:"#3092ed",
      
			content:vip()
		},
		e: {
      title: {
				price: "无限/年",
				ext:"流量无限制",
				sale: "￥498",
				name: "￥198",
				number:"Unlimit-Y2502",
				id:"17"
			},
      color:"#212121",
			content:vip()
		},
		f: {
			title: {
				price: "1000G/月",
				ext:"1000G",
				sale: "￥163",
				name: "￥98",
				number:"1000G+1000G-M2312",
				id:"7"
			},
      color:"#a85307",
			content:vip()
		},
		g: {
      title: {
				price: "终身无限",
				ext:"流量无限制",
				sale: "￥1298",
				name: "￥498",
				number:"Unlimit-F2502",
				id:"19"
			},
      color:"#5b036b",
			content:vip()
		},
		h: {
			title: {
				price: "100G/30天",
				ext:"单次包",
				sale: "￥30",
				name: "￥16",
				number:"100G-30D-M2401",
				id:"10"
			},
      color:"#6b8afd",
			content:vip()
		},
		k: {
			title: {
				price: "10G/30天",
				ext:"新用户VIP大礼包",
				sale: "",
				name: "￥0",
				number:"10G-30D-M2401",
				id:"11"
			},
			content:vip()
		},
	}
}

function free(){
  return {
    a: "部分云盘添加受到限制",
    b: "限制云盘下载速度传输",
    c: "最多添加2个云盘",
    d: "只能传输单个文件",
    e: "",
  }
}

function vip(){
  return {
    a: "任意添加开放的全部云盘",
    b: "不限制云盘添加个数",
    c: "不限制云盘下载速度传输",
    d: "支持多个文件并行传输",
    e: "支持文件夹传输",
    f: "提供人工客服售后保障",
    g: ""
  }
}